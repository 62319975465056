import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectFade, Lazy } from "swiper";
import SlideButton from "./Slide-Button";
import { Button, Modal, Table } from "react-bootstrap";
import terms from "../JSON/tyc.json";

// import Timer2 from "./Timer2.0";
import banners from "../JSON/data.json";

import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import React, { useState, useEffect } from "react";
import { dateInBetween } from "../App";
import { Arrowrightw } from "../icons";
import { SPEEDSIZE_PREFIX } from "../App";

var imgs = [];

function MySwiper({ device }) {
  const [swiper, setSwiper] = useState(null);

  // const DATE_TO_COUNT = "2022-12-18T15:00:00Z";
  const [showTimer, setShowTimer] = useState(false);
  const [curSwiperIndex, setCurSwiperIndex] = useState(0);

  // TYC States
  const [currentTycType, setCurrentTycType] = useState(null);
  const [currentBannerName, setCurrentBannerName] = useState("");
  const [showTycModal, setShowTycModal] = useState(false);
  const [ctaText, setCtaText] = useState("Registrate");
  const [ctaLink, setCtaLink] = useState(
    "https://m.caba.codere.bet.ar/deportes/#/mso/RegistroARPage"
  );
  const [region, setCurrentregion] = useState(null);

  if (!device) imgs = banners.desktop_slide_list.filter(BannerFilter);
  else imgs = banners.mobile_slide_list.filter(BannerFilter);

  useEffect(() => {
    try {
      if (showTycModal) swiper.autoplay.pause();
    } catch (error) {
      console.log(error);
    }
  }, [showTycModal]);

  const indexHandler = (swiper) => {
    setSwiper(swiper);
    if (imgs[swiper.realIndex].ctaText) {
      setCtaText(imgs[swiper.realIndex].ctaText);
      setCtaLink(imgs[swiper.realIndex].ctaURL);
    } else {
      setCtaText("Registrate");
      setCtaLink("https://m.caba.codere.bet.ar/deportes/#/mso/RegistroARPage");
    }

    setCurrentTycType(
      imgs[swiper.realIndex].tycType ? imgs[swiper.realIndex].tycType : ""
    );

    if (imgs[swiper.realIndex].name) {
      setCurrentBannerName(imgs[swiper.realIndex].name);
    }

    if (imgs[swiper.realIndex].region) {
      setCurrentregion(imgs[swiper.realIndex].region);
    }
  };

  function BannerFilter(banner) {
    if (!banner.scheduled) return banner;
    else {
      if (dateInBetween(banner)) return banner;
    }
  }

  const modalGenerator = () => {
    return (
      <>
        <Modal
          centered
          show={showTycModal}
          onHide={() => setShowTycModal(false)}
          contentClassName="terms-tyc"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="test">
                <h5>Términos y condiciones</h5>
                <h5>{currentBannerName}</h5>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {currentTycType && tycGenerator(currentTycType)}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setShowTycModal(false)}
              className="accept-btn"
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const tycGenerator = (type) => {
    const location = window.location.host;
    return (
      <iframe
        width="100%"
        height="400px"
        src={
          location.includes("codere.bet.ar")
            ? `https://www.codere.bet.ar/assets/tyc/arg-tyc-${type}.html` // for prd env
            : `https://blog.codere.bet.ar/terminos-y-condiciones/${type}` // for testing env
        }
      />
    );
  };

  return (
    <>
      <div id="carousel-section">
        <Swiper
          onSlideChange={(swiper) => indexHandler(swiper)}
          modules={[Pagination, EffectFade, Autoplay, Lazy]}
          pagination={{ clickable: true }}
          effect={"fade"}
          autoplay={{ delay: 3250, disableOnInteraction: true }}
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          init={false}
        >
          {imgs.map((item, index) => (
            <SwiperSlide key={index}>
              <a href={ctaLink}>
                <img
                  width={device ? "300" : "400"}
                  height={device ? "410" : "140"}
                  src={`${SPEEDSIZE_PREFIX}${item.image}`}
                  alt={item.name}
                />
              </a>

              {/* Timer for live Text */}
              {/* {showTimer && new Date() < new Date(DATE_TO_COUNT) && (
                    <Timer2 />
                  )} */}
            </SwiperSlide>
          ))}
        </Swiper>
        {!device ? (
          <div id="des-reg">
            <Button
              href={ctaLink}
              className="central-regis gl-effect"
              rel={"nofollow"}
            >
              {ctaText}
              <Arrowrightw />
            </Button>

            {region === "caba" && (
              <p className="regionText">Disponible únicamente dentro de CABA</p>
            )}

            {currentTycType && (
              <span className="tyc" onClick={() => setShowTycModal(true)}>
                Términos y condiciones
              </span>
            )}
          </div>
        ) : (
          <div className="ReactSwipeButton mobile_regis">
            <SlideButton regis={ctaLink} ctaText={ctaText} region={region} />
            {currentTycType && (
              <div className="mob-tyc">
                <span className="tyc" onClick={() => setShowTycModal(true)}>
                  Términos y condiciones
                </span>
              </div>
            )}
          </div>
        )}
        {modalGenerator()}
      </div>
    </>
  );
}
export default MySwiper;
