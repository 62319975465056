import gameCSS from "../CSS/games.module.css";

import { Button } from "react-bootstrap";
import { Slide } from "react-awesome-reveal";
import { Arrowrightw } from "../icons";
import { replaceSpanishCharacters } from "./Games";

function TopGame({ game }) {
  const names = {
    MGS: "Microgaming",
    EVG: "Evolution",
  };
  const gamePrefix = `https://m.caba.codere.bet.ar/deportes/#/casino/CasinoPage?lobby=ArgentinaCaba&playgame=`;
  const imgPrefix = "https://www.codere.bet.ar/assets/splash-games/desktop";

  return (
    <div className={gameCSS.topGame}>
      <div className={gameCSS.card}>
        <a
          href={`${gamePrefix}${replaceSpanishCharacters(game.name)}`}
          rel="nofollow"
        >
          <img
            className="topGameImage"
            src={`${imgPrefix}/${replaceSpanishCharacters(game.name)
              .toLowerCase()
              .replaceAll(/ /g, "-")}.webp`}
            alt={game.name}
          />
          <Slide direction="top" duration={1500}>
            <img
              className={`${gameCSS.game_logo} top-logo-${game.id}`}
              src={`${imgPrefix}/${replaceSpanishCharacters(game.name)
                .toLowerCase()
                .replaceAll(/ /g, "-")}-logo.webp`}
              alt={`${game.name}-logo`}
            />
          </Slide>
        </a>
        <div className={gameCSS.game_info}>
          <h3 className={gameCSS.topGameTitle}>
            {game.name.split(" ").slice(1).join(" ")}
          </h3>
          <div className={gameCSS.topGameSponsor}>
            {names[game.name.split(" ")[0]]}
          </div>
        </div>
        <div className={gameCSS.jugar_div}>
          <Button
            className="jugar-regis rounded-pill d-flex justify-content-around align-items-center"
            size="lg"
            href={`${gamePrefix}${replaceSpanishCharacters(game.name)}`}
          >
            Jugá Ahora
            <div id={gameCSS.game_svg} style={{ margin: "2px 0 0 5px" }}>
              <Arrowrightw />
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TopGame;
