import React, { Component } from 'react';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
		this.handleError = this.handleError.bind(this);
	}

	componentDidMount() {
		// Override console.error to catch errors
		const originalConsoleError = console.error;
		console.error = (...args) => {
			this.handleError(...args);
			originalConsoleError.apply(console, args);
		};
	}

	handleError(...args) {
		const errorMessages = [
			"Access to font at 'https://www.codere.bet.ar/Fonts/HighSchool-V3.1.ttf' from origin 'http://localhost:3000' has been blocked by CORS policy: No 'Access-Control-Allow-Origin' header is present on the requested resource.",
			"GET https://www.codere.bet.ar/Fonts/HighSchool-V3.1.ttf net::ERR_FAILED 200 (OK)"
		];

		const errorString = args.join(' ');

		if (errorMessages.some(msg => errorString.includes(msg))) {
			this.loadFallbackFont();
		}
	}

	loadFallbackFont() {
		const link = document.createElement('link');
		link.href = 'https://github.com/Codere2022/spain-public-cdn/blob/main/fonts/HighSchool-V3.1.ttf';
		link.rel = 'stylesheet';
		document.head.appendChild(link);
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		console.error('ErrorBoundary caught an error', error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <h1>Something went wrong.</h1>;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
