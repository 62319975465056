import { Route, Routes, useLocation } from "react-router-dom";
import Inicio from "../Components/Inicio";
import Basketball from "../Components/seo/sports/events/Basketball";
import Mundeal from "../Components/seo/sports/events/Mundeal";
import Formula from "../Components/seo/sports/events/Formula";
import EventosDeportivos from "../Components/seo/sports/EventosDeportivos";
import Casino from "../Components/seo/casino/Casino";
import Slots from "../Components/seo/casino/Slots";
import CasinoLive from "../Components/seo/casino/CasinoLive";
import Blackjack from "../Components/seo/casino/Blackjack";
import Baccarat from "../Components/seo/casino/Baccarat";
import Ruleta from "../Components/seo/casino/Ruleta";
import Navigations from "../Components/seo/Navigations";
import { useEffect, useState } from "react";
import Baseball from "../Components/seo/sports/events/Baseball";
import SuperBowl from "../Components/seo/sports/events/SuperBowl";

export default function Routing({ device }) {
  const location = useLocation();
  const [activated, setActivated] = useState(0);

  useEffect(() => {
    setActivated(activeHandler);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const activeHandler = () => {
    switch (location.pathname) {
      default:
        return 0;
      case "/casino":
      case "/eventos-deportivos/apuestas-mundial-baloncesto":
        return 1;
      case "/casino/casino-en-vivo":
      case "/eventos-deportivos/apuestas-grand-prix":
        return 2;
      case "/casino/slots":
      case "/eventos-deportivos/apuestas-mundial-fifa":
        return 3;
      case "/casino/blackjack":
      case "/eventos-deportivos/apuestas-serie-mundial":
        return 4;
      case "/casino/baccarat":
      case "/casino/ruleta":
      case "/eventos-deportivos/apuestas-superbowl":
        return 5;
      case "/casino/ruleta":
        return 6;
    }
  };
  return (
    <>
      {location.pathname.includes("eventos") ||
      location.pathname.includes("casino") ? (
        <Navigations
          index={activated}
          type={location.pathname.includes("eventos") && !location.hash ? 0 : 1}
        />
      ) : (
        <></>
      )}

      <Routes location={location}>
        <Route path="/" element={<Inicio device={device} />} />

        <Route exact path="eventos-deportivos">
          <Route exact path="" element={<EventosDeportivos flag={device} />} />
          <Route
            exact
            path="apuestas-mundial-baloncesto"
            element={<Basketball flag={device} />}
          />
          <Route
            exact
            path="apuestas-grand-prix"
            element={<Formula flag={device} />}
          />
          <Route
            exact
            path="apuestas-mundial-fifa"
            element={<Mundeal flag={device} />}
          />
          <Route
            exact
            path="apuestas-serie-mundial"
            element={<Baseball flag={device} />}
          />
          <Route
            exact
            path="apuestas-superbowl"
            element={<SuperBowl flag={device} />}
          />
        </Route>
        <Route exact path="casino">
          <Route exact path="" element={<Casino flag={device} />} />
          <Route
            exact
            path="casino-en-vivo"
            element={<CasinoLive flag={device} />}
          />
          <Route exact path="slots" element={<Slots flag={device} />} />
          <Route exact path="blackjack" element={<Blackjack flag={device} />} />
          <Route exact path="baccarat" element={<Baccarat flag={device} />} />
          <Route exact path="ruleta" element={<Ruleta flag={device} />} />
        </Route>
      </Routes>
    </>
  );
}
