import Floating from "../../Floating";
import TableOfContents from "../../TableOfContents";
import { Accordion, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useEffect } from "react";
import { CodereHelmet } from "../../../../App";

export default function SuperBowl(props) {
  useEffect(() => {
    let title = "Apuestas para el Superbowl » Bono $200.000 | Codere®";
    let desc =
      "Descubrí cómo apostar en el Super Bowl desde Argentina y sumate a la emoción del evento más esperado del año en Codere la mejor Casa de Apuestas.";

    let can = "https://www.codere.bet.ar/eventos-deportivos/apuestas-superbowl";

    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Dónde se suele jugar el Super Bowl?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Se juega en estadios destacados de Estados Unidos, seleccionados por la NFL por su capacidad y prestigio. Cada sede es elegida para ofrecer una experiencia espectacular tanto para los jugadores como para los millones de espectadores que sintonizan este evento anualmente.",
          },
        },
        {
          "@type": "Question",
          name: "¿Quiénes suelen actuar en el espectáculo de medio tiempo del Super Bowl?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Los artistas del espectáculo de medio tiempo del Super Bowl son reconocidos mundialmente por su éxito y popularidad. Sus actuaciones incluyen coreografías elaboradas, efectos especiales e invitados sorpresa, ofreciendo un espectáculo de alto impacto visual y musical.",
          },
        },
      ],
    };
    CodereHelmet(title, desc, can, [json]);
  }, []);

  const prefix = `https://www.codere.bet.ar/seopages/eventos-deportivos/SuperBowl/imgs/${
    props.flag ? "M" : "D"
  }-superbowl-`;
  const jpg = ".webp";

  const top_par = {
    h1: "Aprendé sobre las apuestas en el Super Bowl desde Argentina ",
    p: "Descubrí cómo apostar en el Super Bowl desde Argentina y sumate a la emoción del evento deportivo más esperado del año. Aprendé todo lo que necesitás saber para hacer tus apuestas y vivir la adrenalina del Super Bowl al máximo.",
  };

  const table_list = [
    {
      title:
        "Acerca de la final del campeonato de la National Football League (NFL)",
      id: "anchor1",
    },
    {
      title: "Sistema de conferencias de la NFL",
      id: "anchor2",
    },
    {
      title: "El medio tiempo del Super Bowl",
      id: "anchor3",
    },
    {
      title: "Apuesta al MVP del Super Bowl LVII ",
      id: "anchor4",
    },
    {
      title: "Preguntas frecuentes",
      id: "anchor5",
    },
  ];

  const history = {
    id: "anchor1",
    h2: "Acerca de la final del campeonato de la National Football League (NFL)",
    p: "La final del campeonato de la National Football League (NFL), conocida como el Super Bowl, es uno de los eventos deportivos más importantes del mundo. Este partido no solo atrae a millones de espectadores, sino también a una gran cantidad de apostadores que buscan sumarse a la emoción. Apostar en el Super Bowl te permite participar de diversas formas, desde predecir el ganador hasta apostar en detalles específicos del juego, como quién anotará el primer touchdown. Aprendé cómo hacer tus apuestas y viví la adrenalina del Super Bowl en Codere.",
  };

  const tips = {
    id: "anchor2",
    h2: "Sistema de conferencias de la NFL",
    p: "El sistema de conferencias de la NFL está compuesto por dos conferencias principales: la Conferencia Americana (AFC) y la Conferencia Nacional (NFC). Cada una de estas conferencias está dividida en cuatro divisiones geográficas: Norte, Sur, Este y Oeste. En total, hay 32 equipos en la NFL, con 16 equipos en cada conferencia. Durante la temporada regular, los equipos compiten dentro de sus respectivas conferencias y divisiones en un programa de 17 semanas. Al finalizar la temporada regular, los mejores equipos de cada división, así como un número determinado de equipos con los mejores récords en general, avanzan a los playoffs. Los playoffs determinan qué equipos de cada conferencia llegarán al Super Bowl, el juego final que determina al campeón de la NFL.",

    subtitles: [
      {
        h3: "Conferencia Nacional (NFC)",
        text: "La Conferencia Nacional (NFC) es una de las dos conferencias principales de la National Football League (NFL) de los Estados Unidos. La NFC está compuesta por 16 equipos divididos en cuatro divisiones geográficas: Norte, Sur, Este y Oeste. Los equipos de la NFC compiten entre sí durante la temporada regular y los playoffs para determinar quién representará a la conferencia en el Super Bowl.",
      },
      {
        h3: "Conferencia Americana (AFC)",
        text: "Es una de las dos conferencias principales de la National Football League (NFL) de los Estados Unidos. Junto con la Conferencia Nacional (NFC), la AFC forma parte del sistema de conferencias de la NFL. La AFC está compuesta por 16 equipos divididos en cuatro divisiones geográficas: Norte, Sur, Este y Oeste. Los equipos de la AFC compiten entre sí durante la temporada regular y los playoffs para determinar quién representará a la conferencia en el Super Bowl.",
      },
    ],
  };

  const como_hacer = {
    id: "anchor3",
    h2: "El medio tiempo del Super Bowl ",
    p: "El espectáculo del medio tiempo del Super Bowl es uno de los momentos más esperados del evento. Este segmento presenta actuaciones en vivo de artistas musicales y bandas de renombre mundial. Estas presentaciones suelen ser elaboradas y llenas de efectos especiales, baile y producción de alto nivel. El medio tiempo del Super Bowl se ha convertido en un espectáculo icónico que atrae a una audiencia masiva tanto dentro como fuera del estadio, convirtiéndose en un punto destacado de la cultura pop cada año.",
  };

  const tipos = {
    id: "anchor4",
    h2: "Apuesta al MVP del Super Bowl ",
    p: "Las apuestas al MVP del Super Bowl son apuestas que se hacen para predecir quién será nombrado el Jugador Más Valioso (MVP) del Super Bowl. El MVP es el jugador que se considera que ha tenido el mayor impacto en el resultado del juego, ya sea a través de actuaciones destacadas, jugadas clave o liderazgo en momentos cruciales. Las apuestas al MVP del Super Bowl permiten a los apostadores hacer sus predicciones sobre quién creen que será el MVP antes del inicio del juego, ofreciendo la oportunidad de ganar si su elección resulta ser correcta.",
    subtitles: [
      {
        h3: "Jugadores clave ",
        text: 'Los "jugadores clave" son aquellos miembros de un equipo deportivo que se consideran especialmente importantes para el éxito del equipo. Estos jugadores suelen destacarse por su habilidad, talento, liderazgo o capacidad para influir en el juego de alguna manera significativa. Los jugadores clave pueden ser estrellas destacadas, veteranos con experiencia, o incluso jugadores emergentes que tienen un impacto significativo en el rendimiento del equipo. Identificar a los jugadores clave es importante para los entrenadores y estrategas, ya que pueden planificar estrategias y tácticas en torno a ellos, y su rendimiento puede tener un gran impacto en los resultados del equipo.',
      },
    ],
  };

  const preguntas = {
    id: "anchor5",
    h2: "Preguntas frecuentes ",
    inner: [
      {
        h3: "¿Dónde se suele jugar el Super Bowl? ",
        p: "Se juega en estadios destacados de Estados Unidos, seleccionados por la NFL por su capacidad y prestigio. Cada sede es elegida para ofrecer una experiencia espectacular tanto para los jugadores como para los millones de espectadores que sintonizan este evento anualmente.",
      },
      {
        h3: "¿Quiénes suelen actuar en el espectáculo de medio tiempo del Super Bowl? ",
        p: "Los artistas del espectáculo de medio tiempo del Super Bowl son reconocidos mundialmente por su éxito y popularidad. Sus actuaciones incluyen coreografías elaboradas, efectos especiales e invitados sorpresa, ofreciendo un espectáculo de alto impacto visual y musical. ",
      },
    ],
  };

  return (
    <>
      <Floating text="La jugada perfecta" />

      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(https://www.codere.bet.ar/seopages/eventos-deportivos/SuperBowl/imgs/${
            props.flag ? "M" : "D"
          }-header.webp)`,
          backgroundSize: "cover",
        }}
      ></div>

      <Container>
        <div className="event mt-3">
          <Container>
            <h1>{top_par.h1}</h1>
            <p>{top_par.p}</p>

            {!props.flag ? <TableOfContents table={table_list} /> : <></>}

            <div className="mt-3" id={history.id}>
              <h2>{history.h2}</h2>
            </div>
            <p>{history.p}</p>

            <div className="mt-4" id={tips.id}>
              <h2>{tips.h2}</h2>
              <p>{tips.p}</p>
              {tips.subtitles.map((inn, k) => (
                <div key={k}>
                  <h3>{inn.h3}</h3>
                  <p>{inn.text}</p>
                </div>
              ))}
            </div>

            <div className="mt-3" id={como_hacer.id}>
              <h2>{como_hacer.h2}</h2>
              <p>{como_hacer.p}</p>
            </div>

            <div className="mt-3" id={tipos.id}>
              <h2>{tipos.h2}</h2>
              <p>{tipos.p}</p>
              {tipos.subtitles.map((inn, k) => (
                <div key={k}>
                  <h3>{inn.h3}</h3>
                  <p>{inn.text}</p>
                </div>
              ))}
            </div>

            <div id={preguntas.id}>
              <Accordion>
                {preguntas.inner.map((inn, k) => (
                  <Accordion.Item key={k} eventKey={k}>
                    <Accordion.Header as={"h3"}>{inn.h3}</Accordion.Header>
                    <Accordion.Body>{inn.p}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <LazyLoadImage src={prefix + "1" + jpg} width="100%" />
          </Container>
        </div>
      </Container>
    </>
  );
}
