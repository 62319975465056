import Floating from "../../Floating";
import TableOfContents from "../../TableOfContents";
import { Accordion, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useEffect } from "react";
import { CodereHelmet } from "../../../../App";
import { SPEEDSIZE_PREFIX } from "../../../../App";

export default function Baseball(props) {
  useEffect(() => {
    let title = "Apuestas Serie Mundial de Béisbol » Bono $200.000 | Codere®";
    let desc =
      "Aquí las emociones alcanzan su punto máximo. En esta guía conocerás más acerca del jugo más importante de béisbol y los tipos de apuestas en Codere.";
    let can =
      "https://www.codere.bet.ar/eventos-deportivos/apuestas-serie-mundial";

    let json = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "¿Dónde se suele celebrar la Serie Mundial de Béisbol?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "La Serie Mundial de Béisbol se celebra tradicionalmente en Estados Unidos (en diferentes ciudades), donde se enfrentan los campeones de la Liga Americana y la Liga Nacional para determinar al campeón mundial de béisbol.",
          },
        },
        {
          "@type": "Question",
          name: "¿Qué equipo tiene más Serie Mundial?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Los New York Yankees son el equipo con más Series Mundiales, con un total impresionante de 27 títulos, lo que refleja su dominio histórico en el béisbol profesional.",
          },
        },
      ],
    };
    CodereHelmet(title, desc, can, [json]);
  }, []);

  const prefix = `${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/eventos-deportivos/baseball/imgs/${
    props.flag ? "M" : "D"
  }-Baseball-`;
  const jpg = ".webp";

  const top_par = {
    h1: "Serie Mundial de Béisbol, conocé las cuotas para apostar",
    p: "En la Serie Mundial de Béisbol, las emociones alcanzan su punto máximo. En este evento, no solo se trata del juego en el campo, sino también de las oportunidades de apuestas que tenemos para vos. Exploraremos las cuotas y estrategias para apostar en este emocionante torneo y te sacaremos algunas dudas.",
  };
  const history = {
    id: "anchor1",
    h2: "Origen de la Serie Mundial de la MLB",
    p: "La Serie Mundial de la MLB tiene sus raíces en el año 1903, cuando los campeones de la Liga Americana y la Liga Nacional se enfrentaron en un torneo de postemporada. El propósito original era determinar la supremacía entre las dos ligas rivales. Desde entonces, la Serie Mundial se ha convertido en el evento cumbre del béisbol profesional, atrayendo a aficionados de todo el mundo y proporcionando momentos memorables en la historia del deporte.",
    subtitles: [
      {
        h3: "Liga Nacional ",
        p: "La Liga Nacional es una de las dos ligas principales de béisbol en los Estados Unidos. Fundada en 1876, cuenta con 15 equipos y es conocida por su estilo de juego tradicional y estratégico. ",
      },
      {
        h3: "Liga Americana ",
        p: "La Liga Americana es una de las dos ligas principales de béisbol en los Estados Unidos. Establecida en 1901, cuenta con 15 equipos y se caracteriza por su estilo de juego dinámico y ofensivo. ",
      },
      {
        h3: "¿Por qué se le llama Clásico de Otoño?",
        p: 'Se le llama "Clásico de Otoño" a la Serie Mundial de la MLB porque tradicionalmente se lleva a cabo durante el otoño en los Estados Unidos. El término evoca la atmósfera única de los juegos de béisbol en esa época del año, con las hojas cambiando de color y el clima fresco. Además, el título sugiere la importancia y la tradición arraigada que rodea a este evento, que se considera el punto culminante de la temporada de béisbol profesional.',
      },
    ],
  };

  const tips = {
    id: "anchor2",
    h2: "Equipos con más series mundiales ganadas ",
    p: "Los equipos con más Series Mundiales ganadas en la historia de las Grandes Ligas de Béisbol (MLB) son:",
    ol: [
      "New York Yankees: Han ganado 27 Series Mundiales.",
      "St. Louis Cardinals: Han ganado 11 Series Mundiales.",
      "Boston Red Sox: Han ganado 9 Series Mundiales.",
      "San Francisco Giants: Han ganado 8 Series Mundiales.",
      "Brooklyn / Los Angeles Dodgers: Han ganado 7 Series Mundiales.",
    ],
    inner: [
      {
        h3: "Ganador del partido",
        text: "El tipo de apuesta de “Ganador del partido” consiste en acertar quién será el ganador del juego al dar el pitazo final y haberse cumplido el tiempo reglamentario.",
      },
      {
        h3: "Hándicap",
        text: "El tipo de apuesta “hándicap” consiste en la ventaja o desventaja que se le da a un determinado equipo cuando el mismo no es el favorito (Underdog) para que pueda ser un encuentro equitativo.",
      },
      {
        h3: "Más/Menos Puntos Totales",
        text: "El tipo de apuesta “Más/Menos Puntos” consiste en pronosticar el estimado de puntos totales que se darán dentro del partido no solo de un equipo sino la suma de los puntos que obtengan ambos equipos.",
      },
      {
        h3: "Total de puntos Impar/Par",
        text: "El tipo de apuesta “Total de Puntos Impar/Par” consiste en pronosticar el total de puntos Pares o Impares que tendrán un encuentro. Lo que significa, que el resultado se basará en pronosticar si el marcador quedará con una puntuación de número Par o una puntuación de número Impar. Ejemplo 102 o 115.",
      },
    ],
  };

  const como_hacer = {
    id: "anchor3",
    h2: "Jugadores destacados en la actualidad ",
    ol: [
      {
        bold: "Mike Trout: ",
        text: "Estrella versátil con un dominio completo del juego, destacándose tanto en el bateo como en la defensa.",
      },
      {
        bold: "Fernando Tatis Jr.: ",
        text: "Joven promesa que deslumbra con su talento excepcional y su habilidad para cambiar el juego en cualquier momento.",
      },
      {
        bold: "Shohei Ohtani: ",
        text: "Un talento excepcional que está redefiniendo los límites del béisbol, destacándose tanto como lanzador como bateador.",
      },
      {
        bold: "Mookie Betts: ",
        text: "Versátil y dinámico, aporta velocidad, defensa de élite y un bateo consistente que lo convierten en un jugador completo.",
      },
      {
        bold: "Jacob deGrom: ",
        text: "Una mezcla imparable de velocidad, precisión y dominio de sus lanzamientos, estableciéndose como uno de los mejores lanzadores.",
      },
    ],
  };

  const tipos = {
    id: "anchor4",
    h2: "Tipos de apuestas ",
    p: "En Codere, podés encontrar una variedad de opciones de apuestas, que incluyen apuestas sencillas, combinadas, en vivo, de hándicap, de más/menos, de resultado exacto, futuras y especiales, ahora desarrollamos algunas de estas.",
    subtitles: [
      {
        h3: "Ganador del partido ",
        p: "Es una apuesta en la que se predice qué equipo o jugador ganará el evento deportivo. Si el equipo o jugador seleccionado gana, la apuesta se considera ganadora.",
      },
      {
        h3: "Handicap ",
        p: 'El "Handicap" es un tipo de apuesta en el que se ajustan las probabilidades de un evento deportivo para nivelar la competencia. Se asigna una ventaja o desventaja a un equipo o jugador antes del inicio del evento. El objetivo es equilibrar las probabilidades entre los contendientes, ofreciendo oportunidades de apuesta más atractivas.',
      },

      {
        h3: "Handicap Alternativo ",
        p: 'El "Handicap Alternativo" es una variación de la apuesta de handicap estándar que ofrece diferentes opciones de ventajas o desventajas para un equipo o jugador. Esto te permite elegir entre una variedad de escenarios para ajustar las probabilidades y mejorar tus posibilidades de ganar la apuesta.',
      },
      {
        h3: "Más/Menos Puntos Totales ",
        p: `"Más/Menos Puntos Totales" es un tipo de apuesta en la que se predice si el total de carreras anotadas en un partido será mayor o menor que una cifra específica determinada por el sportsbook. Decidirás si el total de carreras anotadas por ambos equipos será superior o inferior al número establecido por el sportsbook. Si el total de carreras anotadas supera la cifra establecida, se considera "más", mientras que si es inferior, se considera "menos".`,
      },
      {
        h3: "Puntos totales ",
        p: "Los 'Puntos Totales' en MLB es una apuesta donde tenés que predecir cuántas carreras van a hacer entre ambos equipos. Tenés que decidir si creés que se van a hacer más o menos carreras que la cantidad que dice el sportsbook",
      },
    ],
  };

  const preguntas = {
    id: "anchor5",
    h2: "Preguntas frecuentes ",
    inner: [
      {
        h3: "¿Dónde se suele celebrar la Serie Mundial de Béisbol? ",
        p: "La Serie Mundial de Béisbol se celebra tradicionalmente en Estados Unidos (en diferentes ciudades), donde se enfrentan los campeones de la Liga Americana y la Liga Nacional para determinar al campeón mundial de béisbol.",
      },
      {
        h3: "¿Qué equipo tiene más Serie Mundial? ",
        p: "Los New York Yankees son el equipo con más Series Mundiales, con un total impresionante de 27 títulos, lo que refleja su dominio histórico en el béisbol profesional.",
      },
    ],
  };

  const table_list = [
    {
      title: "Origen de la Serie Mundial de la MLB",
      id: "anchor1",
    },
    {
      title: "Equipos con más series mundiales ganadas",
      id: "anchor2",
    },
    {
      title: "Jugadores destacados en la actualidad",
      id: "anchor3",
    },
    {
      title: "Tipos de apuestas",
      id: "anchor4",
    },
    {
      title: "Preguntas frecuentes",
      id: "anchor5",
    },
  ];

  return (
    <>
      <Floating text="Bateá como los grandes" />

      <div
        className="top-bg-seo"
        style={{
          backgroundImage: `url(${SPEEDSIZE_PREFIX}https://www.codere.bet.ar/seopages/eventos-deportivos/baseball/imgs/${
            props.flag ? "M" : "D"
          }-header-baseball.webp)`,
          backgroundSize: "cover",
        }}
      ></div>

      <Container>
        <div className="event mt-3">
          <Container>
            <h1>{top_par.h1}</h1>
            <p>{top_par.p}</p>

            {!props.flag ? <TableOfContents table={table_list} /> : <></>}

            <div className="mt-5" id={history.id}>
              <h2>{history.h2}</h2>
            </div>
            <p>{history.p}</p>
            {history.subtitles.map((inn, k) => (
              <div key={k}>
                <h3>{inn.h3}</h3>
                <p>{inn.p}</p>
              </div>
            ))}

            <div className="mt-4" id={tips.id}>
              <h2>{tips.h2}</h2>
              <p>{tips.p}</p>
              <ol>
                {tips.ol.map((inn, k) => (
                  <li className={"text-white"} key={k}>
                    {inn}
                  </li>
                ))}
              </ol>
            </div>

            <div className="mt-5" id={como_hacer.id}>
              <h2>{como_hacer.h2}</h2>
              <ol>
                {como_hacer.ol.map((inn, k) => (
                  <li className={"text-white"} key={k}>
                    <strong>{inn.bold}</strong> <span>{inn.text}</span>
                  </li>
                ))}
              </ol>
            </div>

            <div className="mt-5" id={tipos.id}>
              <h2>{tipos.h2}</h2>
              <p>{tipos.p}</p>
              {tipos.subtitles.map((inn, k) => (
                <div key={k}>
                  <h3>{inn.h3}</h3>
                  <p>{inn.p}</p>
                </div>
              ))}
            </div>

            <div id={preguntas.id}>
              <Accordion>
                {preguntas.inner.map((inn, k) => (
                  <Accordion.Item key={k} eventKey={k}>
                    <Accordion.Header as={"h3"}>{inn.h3}</Accordion.Header>
                    <Accordion.Body>{inn.p}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <LazyLoadImage src={prefix + "3" + jpg} width="100%" />
          </Container>
        </div>
      </Container>
    </>
  );
}
